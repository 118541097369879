<template>
  <v-container fluid>
    <div class='filters d-flex flex-column px-4'>
      <v-toolbar-title class='font-weight-medium mb-3'>Filters</v-toolbar-title>
      <v-row>
        <v-col cols='9'>
          <v-autocomplete
              v-model='preSelectedMembers'
              :items='members'
              :menu-props='{ bottom: true, offsetY: true }'
              item-value='id'
              item-text='name'
              placeholder='Companies'
              class='mt-3'
              outlined
              clearable
              dense
              multiple
              :counter="members.length"
          >
            <template #selection='{item, index}'>
              <v-chip v-if='index < 5'>{{ item.name }}</v-chip>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols='1'>
          <v-checkbox
              v-model='selectedAllMembers'
              class='mb-4 d-inline-block'
              color='primary'
              hide-details
              label="Select All"
          >

          </v-checkbox>
        </v-col>
        <v-col cols='2'>
          <v-btn
              class='mt-3'
              color='primary'
              depressed
              min-width='250'
              @click="applyFilter"
          >Filter</v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'CompanyReportingTable',
  data() {
    return {
      preSelectedMembers: [],
      selectedAllMembers: true,
      applyFilter: () => {
        this.$emit('filterApplied', this.preSelectedMembers);
      }
    }
  },
  computed: {
    ...mapState({
      members: s => {
        return s.adminStore.companyReporting.map(item => {
          return {id: item.id, name: item.memberName}
        });
      },
    })
  },
  watch: {
    selectedAllMembers() {
      this.selectedAllMembers
          ? this.preSelectedMembers = this.members.map(el => el.id)
          : this.preSelectedMembers = []
    },
    members() {
      this.preSelectedMembers = this.members.map(el => el.id);
    }
    
  }
}
</script>

<style scoped>

</style>